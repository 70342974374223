import * as React from "react"
//import { Link } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"

import * as styles from "../styles/policy.module.scss"

const Antisocial = () => {
  return (
    <Layout>
      <Seo title="反社会的勢力に対する基本方針" description="このページは「反社会的勢力に対する基本方針」に関するページです。" />
      <section className="cPageHeader">
        <div className="inner cContainer">
          <div className="content">
            <h2>
              <span className="alphabet">POLICY</span><br />
              反社会的勢力に対する基本方針
            </h2>
          </div>
        </div>
      </section>

      <section className={styles.policy}>
        <div className={`${styles.inner} cContainer`}>
          <div className={styles.content}>
            <p>
              有限会社ブロックチェーンコネクトは、暴力、威力又は詐欺的手法を駆使して経済的利益を追求する集団又は個人、いわゆる「反社会的勢力」（これをとらえるに際しては、暴力団、暴力団関係企業、総会屋、社会運動標榜ゴロ、政治活動標榜ゴロ、特殊知能暴力集団等といった属性要件に着目するとともに、暴力的な要求行為、法的な責任を超えた不当な要求といった行為要件にも着目します。）に対する基本方針を以下のとおり定め、業務の適切性及び健全性の確保に努めます。
            </p>
            <ol>
              <li>会社組織としての対応反社会的勢力による不当要求には対応部署だけではなく、会社全体で対応いたします。</li>
              <li>外部専門機関との連携反社会的勢力による不当要求に備えて、平素から、警察、弁護士等の外部の専門機関と緊密な連携関係を構築いたします。</li>
              <li>取引を含めた一切の関係遮断取引関係を含めて、反社会的勢力とは一切の関係をもちません。また、反社会的勢力による不当要求は拒絶いたします。</li>
              <li>有事における民事と刑事の法的対応反社会的勢力による不当要求に対しては、民事と刑事の両面から法的対応を行います。</li>
              <li>裏取引や資金提供の禁止反社会的勢力による不当要求が、事業活動上の不祥事や従業員の不祥事を理由とする場合であっても、事案を隠蔽するための裏取引及び資金提供は絶対に行いません。</li>
            </ol>
          </div>
        </div>
      </section>
      
    </Layout>
  )
}

export default Antisocial